import React from 'react'
import { graphql } from 'gatsby'
import tw from 'twin.macro'
import contentParser from 'gatsby-wpgraphql-inline-images'

import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import { H1, H2, H3, H4 } from '../../components/Headings'
import Container from '../../components/Container'
import { ButtonLink } from '../../components/Button'
import Img from 'gatsby-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Content from '../../components/Post/Content'
import Testimonials from '../../components/Testimonials'

const PageTemplate = ({ data }) => {
  const page = data.allWpPage.nodes[0]

  const { hero, cardGroup, footerCta } = page.builderServices

  return (
    <>
      <SEO />
      <Layout headerType="commercial">
        {/* Hero */}
        <section tw="relative">
          <div tw="overflow-hidden h-full w-full absolute z-0">
            {hero?.background?.localFile && (
              <Img
                fluid={hero.background.localFile.childImageSharp.fluid}
                style={{ position: 'absolute' }}
                css={[
                  tw`min-w-full min-h-full max-w-full max-h-full transform -translate-x-1/2 -translate-y-1/2 opacity-75`,
                  'z-index: -1; top:50%;left:50%;',
                ]}
              />
            )}

            <div
              css={[
                tw`absolute w-full h-full z-10 opacity-75`,
                `
                        background-color: white;
                        background-image: linear-gradient(145deg, #ffffff 41%, rgba(255, 255, 255, 0.75) 55%, rgba(128, 128, 128, 0) 83%);
                        @media (min-width: 1023.59px){
                          background-color: transparent;
                        }`,
              ]}
            />
          </div>
          <Container tw="relative py-20 z-20">
            <div tw="lg:(w-1/2 mb-20 pb-20)">
              <H1 tw="text-5xl mb-0">{hero.headline}</H1>
              <p tw="text-3xl mb-5">{hero.subHeadline}</p>
              <p tw="text-gray-700 text-xl leading-8 mb-10 ">{hero.subtext}</p>
              <ButtonLink to="/comercial-quote" large>
                {hero.ctaButtonText}
                <FontAwesomeIcon icon={faChevronRight} tw="ml-8" />
              </ButtonLink>
            </div>
          </Container>
        </section>

        {/* Card Group */}
        <section>
          <Container tw="grid gap-12 lg:(grid-cols-2) my-20">
            {cardGroup.cards.map(card => (
              <article>
                <Img
                  tw="mb-5"
                  fluid={card.image.localFile.childImageSharp.fluid}
                />
                <H3>{card.headline}</H3>

                <Content
                  tw="text-gray-600 text-xl"
                  dangerouslySetInnerHTML={{ __html: card.content }}
                />
              </article>
            ))}
          </Container>
        </section>

        <section tw="mb-24">
          <Container>
            <Testimonials.Carousel />
          </Container>
        </section>

        {/* Footer CTA */}
        <section>
          <Container tw="text-center content-center ">
            <H2 tw="text-5xl">{footerCta.headline}</H2>
            <p tw="text-lg leading-relaxed text-gray-600 lg:(px-10 mx-10) xl:(px-24 mx-24) mb-8">
              {footerCta.subtext}
            </p>
            <ButtonLink large to="/commercial-quote">
              {footerCta.ctaButtonText}
              <FontAwesomeIcon icon={faChevronRight} tw="ml-8" />
            </ButtonLink>
          </Container>
        </section>
      </Layout>
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        id
        title
        content
        ...FeaturedImage
        builderServices {
          fieldGroupName
          hero: builderServicesHero {
            headline: builderServicesHeadline
            subHeadline: builderServicesSubHeadline
            subtext: builderServicesSubtext
            ctaButtonText: builderServicesCtaButtonText
            background: builderServicesBackground {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          cardGroup: builderServicesCardGroup {
            cards: builderServicesCards {
              headline: builderServicesCardHeadline
              content: builderServicesCardContent
              image: builderServicesCardImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 960) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          footerCta: builderServicesFooterCta {
            headline: builderServicesFooterHeadline
            subtext: builderServicesFooterSubtext
            ctaButtonText: builderServicesFooterCtaButtonText
          }
        }
      }
    }
  }
`
